define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-composer-preview", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="wizard-composer-preview d-editor-preview-wrapper">
    <div class="d-editor-preview">
      {{html-safe field.preview_template}}
    </div>
  </div>
  
  */
  {
    "id": "UCp4BStV",
    "block": "[[[10,0],[14,0,\"wizard-composer-preview d-editor-preview-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"d-editor-preview\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,0,[\"field\",\"preview_template\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `field` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-composer-preview.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.field}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-composer-preview.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});