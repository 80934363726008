define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input
    id={{this.field.id}}
    @type="checkbox"
    @checked={{this.field.value}}
    tabindex={{this.field.tabindex}}
    class={{this.fieldClass}}
  />
  
  */
  {
    "id": "uOEHfoKg",
    "block": "[[[8,[39,0],[[16,1,[30,0,[\"field\",\"id\"]]],[16,\"tabindex\",[30,0,[\"field\",\"tabindex\"]]],[16,0,[30,0,[\"fieldClass\"]]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"field\",\"value\"]]]],null],[1,\"\\n\"]],[],false,[\"input\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-checkbox.hbs",
    "isStrictMode": false
  });
});