define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/wizard-subscription-badge", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <svg width="300px" height="300px" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="pavilion-logo" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path id="Combined-Shape" stroke="currentColor" stroke-width="35" d="M41.1381822,291.00006 L40.5778853,130.009744 M258.850727,291.638415 L259.290397,130.37133 M36.0002279,140.721678 L139.995368,36.2122772 M263.350577,141.009083 L138.927245,16.2478517"></path>
    </g>
  </svg>
  <span>{{label}}</span>
  
  */
  {
    "id": "mhAuM3md",
    "block": "[[[10,\"svg\"],[14,\"width\",\"300px\"],[14,\"height\",\"300px\"],[14,\"viewBox\",\"0 0 300 300\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n  \"],[10,\"g\"],[14,1,\"pavilion-logo\"],[14,\"stroke\",\"none\"],[14,\"stroke-width\",\"1\"],[14,\"fill\",\"none\"],[14,\"fill-rule\",\"evenodd\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,1,\"Combined-Shape\"],[14,\"stroke\",\"currentColor\"],[14,\"stroke-width\",\"35\"],[14,\"d\",\"M41.1381822,291.00006 L40.5778853,130.009744 M258.850727,291.638415 L259.290397,130.37133 M36.0002279,140.721678 L139.995368,36.2122772 M263.350577,141.009083 L138.927245,16.2478517\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,1],[12],[41,[28,[32,0],[\"label\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"label\"],[[28,[32,1],[\"label\"],null]]]]],[[[1,[52,[30,1,[\"label\"]],[28,[30,1,[\"label\"]],null,null],[28,[32,2],[[30,0],\"label\",\"[\\\"The `label` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/wizard-subscription-badge.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.label}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[13],[1,\"\\n\"]],[\"maybeHelpers\"],false,[\"if\",\"label\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/wizard-subscription-badge.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});