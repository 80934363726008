define("discourse/plugins/discourse-custom-wizard/discourse/templates/custom-wizard", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="wizard-column">
    <div class="wizard-column-contents">
      {{outlet}}
    </div>
    <div class="wizard-footer">
      {{#if customWizard}}
        <img src={{logoUrl}} style="background-image: initial; width: 33px; height: 33px;" >
      {{else}}
        <div class="discourse-logo"></div>
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "6/DZsYSe",
    "block": "[[[10,0],[14,0,\"wizard-column\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"wizard-column-contents\"],[12],[1,\"\\n    \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"wizard-footer\"],[12],[1,\"\\n\"],[41,[30,0,[\"customWizard\"]],[[[1,\"      \"],[44,[[28,[37,4],null,[[\"logoUrl\"],[[28,[32,0],[\"logoUrl\"],null]]]]],[[[10,\"img\"],[15,\"src\",[52,[30,1,[\"logoUrl\"]],[28,[30,1,[\"logoUrl\"]],null,null],[28,[32,1],[[30,0],\"logoUrl\",\"[\\\"The `logoUrl` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/custom-wizard.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.logoUrl}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]],[14,5,\"background-image: initial; width: 33px; height: 33px;\"],[12],[13]],[1]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"discourse-logo\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,2],[\"[[\\\"The `customWizard` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/custom-wizard.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.customWizard}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"component\",\"-outlet\",\"if\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/custom-wizard.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});