define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-api-show", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-api", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "@ember/controller", "I18n"], function (_exports, _ajax, _ajaxError, _customWizardApi, _decorators, _computed, _wizard, _controller, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    queryParams: ["refresh_list"],
    loadingSubscriptions: false,
    notAuthorized: (0, _computed.not)("api.authorized"),
    endpointMethods: (0, _wizard.selectKitContent)(["PUT", "POST", "PATCH", "DELETE"]),
    showRemove: (0, _computed.not)("isNew"),
    showRedirectUri: (0, _computed.and)("threeLeggedOauth", "api.name"),
    responseIcon: null,
    contentTypes: (0, _wizard.selectKitContent)(["application/json", "application/x-www-form-urlencoded"]),
    successCodes: (0, _wizard.selectKitContent)([100, 101, 102, 200, 201, 202, 203, 204, 205, 206, 207, 208, 226, 300, 301, 302, 303, 303, 304, 305, 306, 307, 308]),
    authDisabled(saveDisabled, authType, authUrl, tokenUrl, clientId, clientSecret, threeLeggedOauth) {
      if (saveDisabled || !authType || !tokenUrl || !clientId || !clientSecret) {
        return true;
      }
      if (threeLeggedOauth) {
        return !authUrl;
      }
      return false;
    },
    saveDisabled(name, authType) {
      return !name || !authType;
    },
    authorizationTypes: (0, _wizard.selectKitContent)(["none", "basic", "oauth_2", "oauth_3"]),
    isBasicAuth: (0, _computed.equal)("api.authType", "basic"),
    isOauth(authType) {
      return authType && authType.indexOf("oauth") > -1;
    },
    twoLeggedOauth: (0, _computed.equal)("api.authType", "oauth_2"),
    threeLeggedOauth: (0, _computed.equal)("api.authType", "oauth_3"),
    nameClass(isNew) {
      return isNew ? "new" : "saved";
    },
    actions: {
      addParam() {
        this.get("api.authParams").pushObject({});
      },
      removeParam(param) {
        this.get("api.authParams").removeObject(param);
      },
      addEndpoint() {
        this.get("api.endpoints").pushObject({});
      },
      removeEndpoint(endpoint) {
        this.get("api.endpoints").removeObject(endpoint);
      },
      authorize() {
        const api = this.get("api");
        const {
          name,
          authType,
          authUrl,
          authParams
        } = api;
        this.set("authErrorMessage", "");
        if (authType === "oauth_2") {
          this.set("authorizing", true);
          (0, _ajax.ajax)(`/admin/wizards/apis/${name.underscore()}/authorize`).catch(_ajaxError.popupAjaxError).then(result => {
            if (result.success) {
              this.set("api", _customWizardApi.default.create(result.api));
            } else if (result.failed && result.message) {
              this.set("authErrorMessage", result.message);
            } else {
              this.set("authErrorMessage", "Authorization Failed");
            }
            setTimeout(() => {
              this.set("authErrorMessage", "");
            }, 6000);
          }).finally(() => this.set("authorizing", false));
        } else if (authType === "oauth_3") {
          let query = "?";
          query += `client_id=${api.clientId}`;
          query += `&redirect_uri=${encodeURIComponent(api.redirectUri)}`;
          query += `&response_type=code`;
          if (authParams) {
            authParams.forEach(p => {
              query += `&${p.key}=${encodeURIComponent(p.value)}`;
            });
          }
          window.location.href = authUrl + query;
        }
      },
      save() {
        const api = this.get("api");
        const name = api.name;
        const authType = api.authType;
        let error;
        if (!name || !authType) {
          return;
        }
        let data = {
          auth_type: authType
        };
        if (api.title) {
          data["title"] = api.title;
        }
        if (api.get("isNew")) {
          data["new"] = true;
        }
        let requiredParams;
        if (authType === "basic") {
          requiredParams = ["username", "password"];
        } else if (authType === "oauth_2") {
          requiredParams = ["tokenUrl", "clientId", "clientSecret"];
        } else if (authType === "oauth_3") {
          requiredParams = ["authUrl", "tokenUrl", "clientId", "clientSecret"];
        }
        if (requiredParams) {
          for (let rp of requiredParams) {
            if (!api[rp]) {
              let key = rp.replace("auth", "");
              error = `${_I18n.default.t(`admin.wizard.api.auth.${key.underscore()}`)} is required for ${authType}`;
              break;
            }
            data[rp.underscore()] = api[rp];
          }
        }
        const params = api.authParams;
        if (params.length) {
          data["auth_params"] = JSON.stringify(params);
        }
        const endpoints = api.endpoints;
        if (endpoints.length) {
          for (let e of endpoints) {
            if (!e.name) {
              error = "Every endpoint must have a name";
              break;
            }
          }
          data["endpoints"] = JSON.stringify(endpoints);
        }
        if (error) {
          this.set("error", error);
          setTimeout(() => {
            this.set("error", "");
          }, 6000);
          return;
        }
        this.set("updating", true);
        (0, _ajax.ajax)(`/admin/wizards/api/${name.underscore()}`, {
          type: "PUT",
          data
        }).catch(_ajaxError.popupAjaxError).then(result => {
          if (result.success) {
            this.send("afterSave", result.api.name);
          } else {
            this.set("responseIcon", "times");
          }
        }).finally(() => this.set("updating", false));
      },
      remove() {
        const name = this.get("api.name");
        if (!name) {
          return;
        }
        this.set("updating", true);
        (0, _ajax.ajax)(`/admin/wizards/api/${name.underscore()}`, {
          type: "DELETE"
        }).catch(_ajaxError.popupAjaxError).then(result => {
          if (result.success) {
            this.send("afterDestroy");
          }
        }).finally(() => this.set("updating", false));
      },
      clearLogs() {
        const name = this.get("api.name");
        if (!name) {
          return;
        }
        (0, _ajax.ajax)(`/admin/wizards/api/${name.underscore()}/logs`, {
          type: "DELETE"
        }).catch(_ajaxError.popupAjaxError).then(result => {
          if (result.success) {
            this.transitionToRoute("adminWizardsApis").then(() => {
              this.send("refreshModel");
            });
          }
        }).finally(() => this.set("updating", false));
      }
    }
  }, [["method", "authDisabled", [(0, _decorators.default)("saveDisabled", "api.authType", "api.authUrl", "api.tokenUrl", "api.clientId", "api.clientSecret", "threeLeggedOauth")]], ["method", "saveDisabled", [(0, _decorators.default)("api.name", "api.authType")]], ["method", "isOauth", [(0, _decorators.default)("api.authType")]], ["method", "nameClass", [(0, _decorators.default)("api.isNew")]]]));
});