define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-text-field", ["exports", "discourse-common/utils/decorators", "discourse/lib/text-direction", "I18n", "@ember/component/text-field"], function (_exports, _decorators, _textDirection, _I18n, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _textField.default.extend(dt7948.p({
    attributeBindings: ["autocorrect", "autocapitalize", "autofocus", "maxLength", "dir"],
    dir() {
      if (this.siteSettings.support_mixed_text_direction) {
        let val = this.value;
        if (val) {
          return (0, _textDirection.isRTL)(val) ? "rtl" : "ltr";
        } else {
          return (0, _textDirection.siteDir)();
        }
      }
    },
    keyUp() {
      if (this.siteSettings.support_mixed_text_direction) {
        let val = this.value;
        if ((0, _textDirection.isRTL)(val)) {
          this.set("dir", "rtl");
        } else if ((0, _textDirection.isLTR)(val)) {
          this.set("dir", "ltr");
        } else {
          this.set("dir", (0, _textDirection.siteDir)());
        }
      }
    },
    placeholder(placeholderKey) {
      return placeholderKey ? _I18n.default.t(placeholderKey) : "";
    }
  }, [["method", "dir", [_decorators.default]], ["method", "placeholder", [(0, _decorators.default)("placeholderKey")]]]));
});