define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-wizard-show", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/lib/show-modal", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "@ember/string", "@ember/runloop", "@ember/controller", "discourse/lib/copy-text", "I18n", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema"], function (_exports, _decorators, _computed, _showModal, _wizard, _string, _runloop, _controller, _copyText, _I18n, _wizardSchema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    hasName: (0, _computed.notEmpty)("wizard.name"),
    resetCurrentObjects() {
      const currentStep = this.currentStep;
      if (currentStep) {
        const fields = currentStep.fields;
        this.set("currentField", fields && fields.length ? fields[0] : null);
      }
      (0, _runloop.scheduleOnce)("afterRender", () => $("body").addClass("admin-wizard"));
    },
    setId() {
      const wizard = this.wizard;
      if (wizard && !wizard.existingId) {
        this.set("wizard.id", (0, _wizard.generateId)(wizard.name));
      }
    },
    wizardUrl(wizardId) {
      let baseUrl = window.location.href.split("/admin");
      return baseUrl[0] + "/w/" + (0, _string.dasherize)(wizardId);
    },
    nextSessionScheduledLabel(scheduled) {
      return scheduled ? moment(scheduled).format("MMMM Do, HH:mm") : _I18n.default.t("admin.wizard.after_time_time_label");
    },
    wizardFields(currentStepId, saveSubmissions) {
      let steps = this.wizard.steps;
      if (!saveSubmissions) {
        steps = [steps.findBy("id", currentStepId)];
      }
      return (0, _wizard.wizardFieldList)(steps);
    },
    filteredFieldTypes(fieldTypes) {
      const fieldTypeIds = fieldTypes.map(f => f.id);
      const allowedTypeIds = (0, _wizardSchema.filterValues)(this.wizard, "field", "type", fieldTypeIds);
      return fieldTypes.filter(f => allowedTypeIds.includes(f.id));
    },
    getErrorMessage(result) {
      if (result.backend_validation_error) {
        return result.backend_validation_error;
      }
      let errorType = "failed";
      let errorParams = {};
      if (result.error) {
        errorType = result.error.type;
        errorParams = result.error.params;
      }
      return _I18n.default.t(`admin.wizard.error.${errorType}`, errorParams);
    },
    actions: {
      save() {
        this.setProperties({
          saving: true,
          error: null
        });
        const wizard = this.wizard;
        const creating = this.creating;
        let opts = {};
        if (creating) {
          opts.create = true;
        }
        wizard.save(opts).then(result => {
          if (result.wizard_id) {
            this.send("afterSave", result.wizard_id);
          } else if (result.errors) {
            this.set("error", result.errors.join(", "));
          }
        }).catch(result => {
          this.set("error", this.getErrorMessage(result));
          (0, _runloop.later)(() => this.set("error", null), 10000);
        }).finally(() => this.set("saving", false));
      },
      remove() {
        this.wizard.remove().then(() => this.send("afterDestroy"));
      },
      setNextSessionScheduled() {
        let controller = (0, _showModal.default)("next-session-scheduled", {
          model: {
            dateTime: this.wizard.after_time_scheduled,
            update: dateTime => this.set("wizard.after_time_scheduled", dateTime)
          }
        });
        controller.setup();
      },
      copyUrl() {
        const $copyRange = $('<p id="copy-range"></p>');
        $copyRange.html(this.wizardUrl);
        $(document.body).append($copyRange);
        if ((0, _copyText.default)(this.wizardUrl, $copyRange[0])) {
          this.set("copiedUrl", true);
          (0, _runloop.later)(() => this.set("copiedUrl", false), 2000);
        }
        $copyRange.remove();
      }
    }
  }, [["method", "resetCurrentObjects", [(0, _decorators.observes)("currentStep")]], ["method", "setId", [(0, _decorators.observes)("wizard.name")]], ["method", "wizardUrl", [(0, _decorators.default)("wizard.id")]], ["method", "nextSessionScheduledLabel", [(0, _decorators.default)("wizard.after_time_scheduled")]], ["method", "wizardFields", [(0, _decorators.default)("currentStep.id", "wizard.save_submissions", "currentStep.fields.@each.label")]], ["method", "filteredFieldTypes", [(0, _decorators.default)("fieldTypes", "wizard.allowGuests")]]]));
});