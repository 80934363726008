define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-user-selector", ["exports", "discourse-common/utils/decorators", "discourse/helpers/user-avatar", "discourse/lib/user-search", "I18n", "handlebars", "@ember/utils", "@ember/component/text-field"], function (_exports, _decorators, _userAvatar, _userSearch, _I18n, _handlebars, _utils, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const template = function (params) {
    const options = params.options;
    let html = "<div class='autocomplete'>";
    if (options.users) {
      html += "<ul>";
      options.users.forEach(u => {
        html += `<li><a href title="${u.name}">`;
        html += (0, _userAvatar.renderAvatar)(u, {
          imageSize: "tiny"
        });
        html += `<span class='username'>${u.username}</span>`;
        if (u.name) {
          html += `<span class='name'>${u.name}</span>`;
        }
        html += `</a></li>`;
      });
      html += "</ul>";
    }
    html += "</div>";
    return new _handlebars.default.SafeString(html).string;
  };
  var _default = _exports.default = _textField.default.extend(dt7948.p({
    attributeBindings: ["autofocus", "maxLength"],
    autocorrect: false,
    autocapitalize: false,
    name: "user-selector",
    id: "custom-member-selector",
    placeholder(placeholderKey) {
      return placeholderKey ? _I18n.default.t(placeholderKey) : "";
    },
    _update() {
      if (this.get("canReceiveUpdates") === "true") {
        this.didInsertElement({
          updateData: true
        });
      }
    },
    didInsertElement(opts) {
      this._super();
      let self = this,
        selected = [],
        groups = [],
        includeMentionableGroups = this.get("includeMentionableGroups") === "true",
        includeMessageableGroups = this.get("includeMessageableGroups") === "true",
        includeGroups = this.get("includeGroups") === "true",
        allowedUsers = this.get("allowedUsers") === "true";
      function excludedUsernames() {
        // hack works around some issues with allowAny eventing
        const usernames = self.get("single") ? [] : selected;
        return usernames;
      }
      $(this.element).val(this.get("usernames")).autocomplete({
        template,
        disabled: this.get("disabled"),
        single: this.get("single"),
        allowAny: this.get("allowAny"),
        updateData: opts && opts.updateData ? opts.updateData : false,
        dataSource(term) {
          const termRegex = /[^a-zA-Z0-9_\-\.@\+]/;
          let results = (0, _userSearch.default)({
            term: term.replace(termRegex, ""),
            topicId: self.get("topicId"),
            exclude: excludedUsernames(),
            includeGroups,
            allowedUsers,
            includeMentionableGroups,
            includeMessageableGroups
          });
          return results;
        },
        transformComplete(v) {
          if (v.username || v.name) {
            if (!v.username) {
              groups.push(v.name);
            }
            return v.username || v.name;
          } else {
            let excludes = excludedUsernames();
            return v.usernames.filter(function (item) {
              return excludes.indexOf(item) === -1;
            });
          }
        },
        onChangeItems(items) {
          let hasGroups = false;
          items = items.map(function (i) {
            if (groups.indexOf(i) > -1) {
              hasGroups = true;
            }
            return i.username ? i.username : i;
          });
          self.set("usernames", items.join(","));
          self.set("hasGroups", hasGroups);
          selected = items;
          if (self.get("onChangeCallback")) {
            self.sendAction("onChangeCallback");
          }
        },
        reverseTransform(i) {
          return {
            username: i
          };
        }
      });
    },
    willDestroyElement() {
      this._super();
      $(this.element).autocomplete("destroy");
    },
    _clearInput: function () {
      if (arguments.length > 1) {
        if ((0, _utils.isEmpty)(this.get("usernames"))) {
          $(this.element).parent().find("a").click();
        }
      }
    }
  }, [["method", "placeholder", [(0, _decorators.default)("placeholderKey")]], ["method", "_update", [(0, _decorators.observes)("usernames")]], ["field", "_clearInput", [(0, _decorators.observes)("usernames")]]]));
});