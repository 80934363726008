define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-logs-show", ["exports", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-logs", "discourse/routes/discourse", "@ember/array"], function (_exports, _customWizardLogs, _discourse, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      return _customWizardLogs.default.list(params.wizardId);
    },
    setupController(controller, model) {
      controller.setProperties({
        wizard: model.wizard,
        logs: (0, _array.A)(model.logs),
        total: model.total
      });
    }
  });
});