define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-wizard", ["exports", "@ember/controller", "discourse-common/utils/decorators", "@ember/object/computed"], function (_exports, _controller, _decorators, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    creating: (0, _computed.equal)("wizardId", "create"),
    wizardListVal(creating, wizardId) {
      return creating ? null : wizardId;
    },
    messageKey(creating, wizardId) {
      let key = "select";
      if (creating) {
        key = "create";
      } else if (wizardId) {
        key = "edit";
      }
      return key;
    },
    messageUrl: "https://discourse.pluginmanager.org/c/discourse-custom-wizard/documentation"
  }, [["method", "wizardListVal", [(0, _decorators.default)("creating", "wizardId")]], ["method", "messageKey", [(0, _decorators.default)("creating", "wizardId")]]]));
});