define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-api-show", ["exports", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-api", "discourse/routes/discourse"], function (_exports, _customWizardApi, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      if (params.name === "create") {
        return _customWizardApi.default.create({
          isNew: true
        });
      } else {
        return _customWizardApi.default.find(params.name);
      }
    },
    setupController(controller, model) {
      controller.set("api", model);
    }
  });
});