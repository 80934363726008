define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/wizard-subscription-cta", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-icon icon}}{{label}}
  
  */
  {
    "id": "uiTvgg6T",
    "block": "[[[1,[28,[35,0],[[30,0,[\"icon\"]]],null]],[41,[28,[32,0],[\"label\"],null],[[[8,[39,2],null,null,null]],[]],[[[44,[[28,[37,4],null,[[\"label\"],[[28,[32,1],[\"label\"],null]]]]],[[[1,[52,[30,1,[\"label\"]],[28,[30,1,[\"label\"]],null,null],[28,[32,2],[[30,0],\"label\",\"[\\\"The `label` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/wizard-subscription-cta.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.label}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n\"],[1,[28,[32,3],[\"[[\\\"The `icon` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/wizard-subscription-cta.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.icon}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"d-icon\",\"if\",\"label\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/wizard-subscription-cta.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});