define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-time", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{custom-wizard-time-input
    date=time
    onChange=(action "onChange")
    tabindex=field.tabindex
  }}
  
  */
  {
    "id": "ooBhPqD5",
    "block": "[[[1,[28,[35,0],null,[[\"date\",\"onChange\",\"tabindex\"],[[30,0,[\"time\"]],[28,[37,1],[[30,0],\"onChange\"],null],[30,0,[\"field\",\"tabindex\"]]]]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `time` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-time.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.time}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `field` property path was used in the `discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-time.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.field}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"custom-wizard-time-input\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-time.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});