define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-submissions", ["exports", "discourse/routes/discourse", "discourse/lib/ajax"], function (_exports, _discourse, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return (0, _ajax.ajax)(`/admin/wizards/wizard`);
    },
    setupController(controller, model) {
      const showParams = this.paramsFor("adminWizardsSubmissionsShow");
      controller.setProperties({
        wizardId: showParams.wizardId,
        wizardList: model.wizard_list
      });
    },
    actions: {
      changeWizard(wizardId) {
        this.controllerFor("adminWizardsSubmissions").set("wizardId", wizardId);
        this.transitionTo("adminWizardsSubmissionsShow", wizardId);
      }
    }
  });
});