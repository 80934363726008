define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-textarea", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Textarea
    id={{this.field.id}}
    @value={{this.field.value}}
    tabindex={{this.field.tabindex}}
    class={{this.fieldClass}}
    placeholder={{this.field.translatedPlaceholder}}
  />
  
  */
  {
    "id": "/ORPvghC",
    "block": "[[[8,[39,0],[[16,1,[30,0,[\"field\",\"id\"]]],[16,\"tabindex\",[30,0,[\"field\",\"tabindex\"]]],[16,0,[30,0,[\"fieldClass\"]]],[16,\"placeholder\",[30,0,[\"field\",\"translatedPlaceholder\"]]]],[[\"@value\"],[[30,0,[\"field\",\"value\"]]]],null],[1,\"\"]],[],false,[\"textarea\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-textarea.hbs",
    "isStrictMode": false
  });
});