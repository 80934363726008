define("discourse/plugins/discourse-custom-wizard/discourse/controllers/next-session-scheduled", ["exports", "discourse-common/utils/decorators", "@ember/controller"], function (_exports, _decorators, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    title: "admin.wizard.after_time_modal.title",
    setup() {
      this.set("bufferedDateTime", moment(this.model.dateTime));
    },
    submitDisabled(dateTime) {
      return moment().isAfter(dateTime);
    },
    actions: {
      submit() {
        const dateTime = this.get("bufferedDateTime");
        this.get("model.update")(moment(dateTime).utc().toISOString());
        this.send("closeModal");
      },
      dateTimeChanged(dateTime) {
        this.set("bufferedDateTime", dateTime);
      }
    }
  }, [["method", "submitDisabled", [(0, _decorators.default)("bufferedDateTime")]]]));
});